var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-lg-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", lg3: "", "pr-4": "" } },
                [
                  _c("span", { staticClass: "subtitle-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("form.name")) + " "),
                  ]),
                  _c("br"),
                  _c("span", { staticClass: "title-1" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.employee.first_name) +
                        " " +
                        _vm._s(_vm.employee.last_name) +
                        " "
                    ),
                  ]),
                  _c("br"),
                  _c("AppNavButton", {
                    attrs: {
                      small: "",
                      label: "pages.settings.edit_profile",
                      route: {
                        name: "employee.edit",
                        params: { ..._vm.$route.params },
                      },
                    },
                  }),
                  _c(
                    "span",
                    { staticClass: "pl-4" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "nudge-bottom": "10", "offset-y": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-avatar",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "#DDE9FF",
                                            size: "31",
                                          },
                                        },
                                        "v-avatar",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "#081734",
                                            size: "18px",
                                          },
                                        },
                                        [_vm._v("mdi-dots-vertical")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-4" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "justify-start": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "services.add",
                                              query: {
                                                emp: _vm.$route.params.id,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "value-1 small",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "pages.employees.assign_shift"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs6: "",
                    sm4: "",
                    lg3: "",
                    xl2: "",
                    "pt-2": "",
                    "pt-lg-0": "",
                    "pr-2": "",
                    "pr-lg-4": "",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevated-card",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c("div", { staticClass: "card-line green" }),
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("span", { staticClass: "title-1" }, [
                              _vm._v(_vm._s(_vm.employee.assignment_count)),
                            ]),
                            _c("br"),
                            _c(
                              "span",
                              { staticClass: "subtitle-1 text-no-wrap" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("pages.employees.total_assign")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs6: "",
                    sm4: "",
                    lg3: "",
                    xl2: "",
                    "pt-2": "",
                    "pt-lg-0": "",
                    "pr-2": "",
                    "pr-sm-0": "",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevated-card",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c("div", { staticClass: "card-line red" }),
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("span", { staticClass: "title-1" }, [
                              _vm._v(_vm._s(_vm.employee.absent_count)),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _c("span", { staticClass: "subtitle-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("pages.employees.no_leave")) +
                                  " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "py-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "app-border-color",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", sm4: "", lg3: "" } },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.address")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.employee.address) + " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", sm4: "", lg3: "" } },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("pages.employees.joined_date")
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(" " + _vm._s(_vm.createdDate) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs6: "",
                                sm4: "",
                                lg3: "",
                                "pt-4": "",
                                "pt-sm-0": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.hospital")) + " "
                                ),
                              ]),
                              _c("br"),
                              _vm.employee.hospital
                                ? _c("span", { staticClass: "title-3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.employee.hospital.name) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs6: "",
                                sm4: "",
                                lg3: "",
                                "pt-4": "",
                                "pt-lg-0": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("form.qualification")) +
                                    " "
                                ),
                              ]),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "title-3" },
                                [
                                  _vm._l(
                                    _vm.employee.qualifications,
                                    function (item, index) {
                                      return [
                                        _c("span", { key: index }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.qualification.name) +
                                              " "
                                          ),
                                        ]),
                                        index + 1 <
                                        _vm.employee.qualifications.length
                                          ? _c(
                                              "span",
                                              { key: "dash-" + index },
                                              [_vm._v(" | ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: { xs6: "", sm4: "", lg3: "", "pt-4": "" },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.department")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "title-3" },
                                [
                                  _vm._l(
                                    _vm.employee.departments,
                                    function (item, index) {
                                      return [
                                        _c("span", { key: index }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.department.name) +
                                              " "
                                          ),
                                        ]),
                                        index + 1 <
                                        _vm.employee.departments.length
                                          ? _c(
                                              "span",
                                              { key: "dash-" + index },
                                              [_vm._v(" | ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: { xs6: "", sm4: "", lg3: "", "pt-4": "" },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.language")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "title-3" },
                                [
                                  _vm._l(
                                    _vm.employee.languages,
                                    function (item, index) {
                                      return [
                                        _c("span", { key: index }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getLanguage(
                                                  item.language.id
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        index + 1 <
                                        _vm.employee.languages.length
                                          ? _c(
                                              "span",
                                              { key: "dash-" + index },
                                              [_vm._v(" | ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: { xs6: "", sm4: "", lg3: "", "pt-4": "" },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("table.status")) + " "
                                ),
                              ]),
                              _c("br"),
                              _vm.employee.user
                                ? _c("span", { staticClass: "title-3" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.employee.user.is_active
                                            ? this.$t("filters.active")
                                            : this.$t("filters.inactive")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "py-4": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "title-2", attrs: { xs6: "", "pb-4": "" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("pages.employees.assignments")) + " "
                  ),
                ]
              ),
              _c(
                "v-flex",
                {
                  staticClass: "title-2",
                  attrs: { xs6: "", "pb-4": "", "text-right": "" },
                },
                [
                  _c("AppExcelExport", {
                    attrs: {
                      items: _vm.exportData.list,
                      headings: _vm.exportData.headings,
                      name:
                        _vm.employee.first_name + " " + _vm.employee.last_name,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.getAssignmentList(true)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("AppSimpleTable", {
                    attrs: {
                      headings: _vm.headings,
                      items: _vm.list,
                      pagination: _vm.pagination,
                      "empty-title": _vm.$t("messages.info.no_items", {
                        type: _vm.$t("pages.employees.assignments"),
                      }),
                    },
                    on: { paginate: (val) => (_vm.pagination.page = val) },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }