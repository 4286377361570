/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pa-2 pa-sm-4 pa-lg-8>
    <v-flex xs12>
      <v-layout wrap justify-start>
        <v-flex xs12 lg3 pr-4>
          <span class="subtitle-2"> {{ $t("form.name") }} </span>
          <br />
          <span class="title-1">
            {{ employee.first_name }} {{ employee.last_name }}
          </span>
          <br />
          <AppNavButton
            small
            label="pages.settings.edit_profile"
            :route="{ name: 'employee.edit', params: { ...$route.params } }"
          />
          <span class="pl-4">
            <v-menu nudge-bottom="10" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar color="#DDE9FF" size="31" v-bind="attrs" v-on="on">
                  <v-icon color="#081734" size="18px">mdi-dots-vertical</v-icon>
                </v-avatar>
              </template>
              <v-card class="pa-4">
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <router-link
                      :to="{
                        name: 'services.add',
                        query: { emp: $route.params.id },
                      }"
                    >
                      <span style="cursor: pointer" class="value-1 small">
                        {{ $t("pages.employees.assign_shift") }}
                      </span>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-menu>
          </span>
        </v-flex>
        <v-spacer />
        <v-flex xs6 sm4 lg3 xl2 pt-2 pt-lg-0 pr-2 pr-lg-4>
          <v-card outlined rounded="lg" class="elevated-card">
            <div class="card-line green"></div>
            <v-layout wrap justify-start pa-4>
              <v-flex xs12>
                <span class="title-1">{{ employee.assignment_count }}</span>
                <br />
                <span class="subtitle-1 text-no-wrap">
                  {{ $t("pages.employees.total_assign") }}
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 sm4 lg3 xl2 pt-2 pt-lg-0 pr-2 pr-sm-0>
          <v-card outlined rounded="lg" class="elevated-card">
            <div class="card-line red"></div>
            <v-layout wrap justify-start pa-4>
              <v-flex xs12>
                <span class="title-1">{{ employee.absent_count }}</span> <br />
                <span class="subtitle-1">
                  {{ $t("pages.employees.no_leave") }}
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start py-4>
        <v-flex xs12>
          <v-card outlined class="app-border-color" rounded="lg">
            <v-layout wrap justify-start pa-4>
              <v-flex xs6 sm4 lg3>
                <span class="subtitle-2">
                  {{ $t("form.address") }}
                </span>
                <br />
                <span class="title-3">
                  {{ employee.address }}
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3>
                <span class="subtitle-2">
                  {{ $t("pages.employees.joined_date") }}
                </span>
                <br />
                <span class="title-3"> {{ createdDate }} </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4 pt-sm-0>
                <span class="subtitle-2">
                  {{ $t("form.hospital") }}
                </span>
                <br />
                <span class="title-3" v-if="employee.hospital">
                  {{ employee.hospital.name }}
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4 pt-lg-0>
                <span class="subtitle-2">
                  {{ $t("form.qualification") }}
                </span>
                <br />
                <span class="title-3">
                  <template v-for="(item, index) in employee.qualifications">
                    <span :key="index">
                      {{ item.qualification.name }}
                    </span>
                    <span
                      v-if="index + 1 < employee.qualifications.length"
                      :key="'dash-' + index"
                    >
                      |
                    </span>
                  </template>
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4>
                <span class="subtitle-2">
                  {{ $t("form.department") }}
                </span>
                <br />
                <span class="title-3">
                  <template v-for="(item, index) in employee.departments">
                    <span :key="index">
                      {{ item.department.name }}
                    </span>
                    <span
                      v-if="index + 1 < employee.departments.length"
                      :key="'dash-' + index"
                    >
                      |
                    </span>
                  </template>
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4>
                <span class="subtitle-2">
                  {{ $t("form.language") }}
                </span>
                <br />
                <span class="title-3">
                  <template v-for="(item, index) in employee.languages">
                    <span :key="index">
                      {{ getLanguage(item.language.id) }}
                    </span>
                    <span
                      v-if="index + 1 < employee.languages.length"
                      :key="'dash-' + index"
                    >
                      |
                    </span>
                  </template>
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4>
                <span class="subtitle-2">
                  {{ $t("table.status") }}
                </span>
                <br />
                <span class="title-3" v-if="employee.user">
                  {{
                    employee.user.is_active
                      ? this.$t("filters.active")
                      : this.$t("filters.inactive")
                  }}
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout wrap justify-start py-4>
        <v-flex xs6 pb-4 class="title-2">
          {{ $t("pages.employees.assignments") }}
        </v-flex>
        <v-flex xs6 pb-4 class="title-2" text-right>
          <AppExcelExport
            :items="exportData.list"
            :headings="exportData.headings"
            :name="employee.first_name + ' ' + employee.last_name"
            @click.native="getAssignmentList(true)"
          />
        </v-flex>
        <v-flex xs12>
          <AppSimpleTable
            :headings="headings"
            :items="list"
            :pagination="pagination"
            :empty-title="
              $t('messages.info.no_items', {
                type: $t('pages.employees.assignments'),
              })
            "
            @paginate="(val) => (pagination.page = val)"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Helpers from "@/libs/helper/components/helper-functions";
import { _SHIFTS } from "@/constants/shifts";
import { LANGUAGES } from "@/constants/languages";

export default {
  name: "Employee",
  data: () => ({
    headings: [
      { name: "", value: "index", style: "width:80px" },
      // { name: "form.ward", value: "ward" },
      { name: "form.date", value: "date" },
      // { name: "form.case_no", value: "caseNo" },
      { name: "form.start_time", value: "startTime" },
      { name: "form.end_time", value: "endTime" },
      { name: "form.shift", value: "shift" },
      {
        name: "table.actions",
        value: "action",
        style: "max-width:200px",
        options: ["view"],
        view: "service",
      },
    ],
    downloadList: [],
    pagination: {
      per_page: 25,
      page: 1,
      last_page: 1,
      total: 0,
      from: 0,
      to: 0,
    },
  }),
  computed: {
    ...mapGetters({
      getEmployee: "hospital/getEmployee",
      getEmployeeAssignmentsList: "hospital/getEmployeeAssignments",
    }),
    employee() {
      const breadCrumbs = [
        {
          name: "employees",
          text: "pages.employees.title",
        },
        {
          name: this.$route.name,
          params: this.$route.params,
          text: this.getEmployee.first_name + " " + this.getEmployee.last_name,
        },
      ];
      this.$store.commit("app/setBreadCrumbs", breadCrumbs);
      return this.getEmployee;
    },
    list() {
      return this.getEmployeeAssignmentsList.map((itm, index) => {
        return {
          index: this.pagination.from + index,
          id: itm.service.id,
          ward: itm.service.ward_id,
          date: this.$moment(itm.date).format("DD MMM YYYY"),
          caseNo: itm.service.case_no,
          lettering: itm.employee_lettering,
          startTime: itm.start_time,
          endTime: itm.end_time,
          shift: _SHIFTS[itm.shift_id],
        };
      });
    },
    createdDate() {
      return this.$moment(this.getEmployee?.created_at).format("DD MMM YYYY");
    },
    formattedExportData() {
      return this.downloadList.map((itm, index) => {
        return {
          index: index + 1,
          id: itm.service.id,
          date: this.$moment(itm.date).format("DD MMM YYYY"),
          caseNo: itm.service.case_no,
          lettering: itm.employee_lettering,
          startTime: itm.start_time,
          endTime: itm.end_time,
          shift: _SHIFTS[itm.shift_id],
          ward: itm.service?.ward?.name ?? "ward",
          pool: itm.service?.departments.map((dept) => {
            return dept.department?.name ?? "pool";
          }),
          qualification: itm.service?.qualifications.map((qual) => {
            return qual.qualification?.name ?? "Funktion";
          }),
          employee: this.employee.first_name + " " + this.employee.last_name,
        };
      });
    },
    exportData() {
      return {
        headings: {
          "": "index",
          Datum: "date",
          Start: "startTime",
          Ende: "endTime",
          Schicht: "shift",
          Abteilung: "ward",
          Pool: "pool",
          Funktion: "qualification",
          "Mitarbeiter Name": "employee",
        },
        list: this.formattedExportData,
      };
    },
  },
  watch: {
    "pagination.page"() {
      this.updateQueryParams();
      this.getAssignmentList();
    },
  },
  created() {
    this.pagination.page = parseInt(this.$route.query.page) || 1;
    this.getEmployeeDetails(this.$route.params.id);
    this.getAssignmentList();
    // this.getAssignmentList(true);
  },
  methods: {
    ...mapActions({
      getEmployeeDetails: "hospital/getEmployee",
      getEmployeeAssignments: "hospital/getEmployeeAssignments",
    }),
    updateQueryParams() {
      this.$router.replace({
        query: {
          page: this.pagination.page,
        },
      });
    },
    getLanguage(id) {
      return this.$t(
        LANGUAGES.find((itm) => {
          return itm.value === id;
        })?.label
      );
    },
    getAssignmentList(download = false) {
      const payload = {
        ...this.pagination,
        search: this.searchKey,
        gender: this.gender,
        sort_by: ["date"],
        sort_dir: ["asc", "asc", "asc"],
        qualification: this.qualification,
        id: this.$route.params.id,
        download: download,
        fileName: this.employee.first_name + " " + this.employee.last_name,
      };
      this.getEmployeeAssignments(payload).then((res) => {
        if (!download) this.pagination = Helpers.setPagination(res);
        // else this.downloadList = res;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn-toggle {
  border-radius: 7px;
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: #c0cbe0 !important;
}
.v-btn--active::before,
.v-btn--active:hover::before,
.v-btn--active {
  opacity: 1 !important;
  background-color: #dce8ff;
}
.v-btn-toggle > .v-btn.v-btn {
  opacity: 1;
}
.v-btn:focus::before {
  opacity: 0;
}
.v-btn:hover::before {
  background-color: #dce8ff;
  opacity: 0.34;
}
</style>
